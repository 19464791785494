import { colors, fonts } from '../../theme';
import { mq } from 'styled-gen';
import { rgba } from 'polished';
import backgroundImage from '../../images/background.svg';
import styled, { css } from 'styled-components';

const AboutContent = styled.div`
  color: ${colors.n06};
  display: none;
  font-size: 14px;
  margin-bottom: auto;
  margin-top: 2rem;
  max-width: 44rem;
  padding: 0 2rem 4rem 2rem;

  ${({ isActive }) =>
    isActive &&
    css`
      display: block;
    `}

  p {
    & + p {
      margin-top: 1.5rem;
    }

    & + ul {
      margin-top: 1rem;
    }
  }

  ul {
    list-style: disc;
    padding-left: 1.5rem;

    li {
      & + li {
        margin-top: 0.5rem;
      }
    }

    & + p {
      margin-top: 1.5rem;
    }
  }

  h2 {
    color: ${colors.p06};
    font-size: 1.75rem;
    font-weight: ${fonts.weights.semibold};
    margin-top: 2rem;

    & + p {
      margin-top: 2rem;
    }
  }

  h4 {
    font-size: 1.25rem;
    font-weight: ${fonts.weights.semibold};
    margin-top: 2rem;

    & + p {
      margin-top: 2rem;
    }
  }
`;

const Background = styled.div`
  align-items: center;
  background-color: ${colors.n03};
  background-image: url(${backgroundImage});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
`;

const BrandName = styled.h1`
  font-size: 0.875rem;
  line-height: 1.5;
  margin-top: 1rem;
  position: relative;

  &::before {
    background-color: ${colors.p05};
    content: '';
    height: 3.4rem;
    left: -1rem;
    position: absolute;
    top: 0.25rem;
    width: 1px;
  }
`;

const Content = styled.div`
  background-color: ${colors.n01};
  border-bottom-left-radius: ${25 / 2}rem;
  border-bottom-right-radius: ${25 / 2}rem;
  box-shadow: 0 0 2.5rem ${rgba(colors.n07, 0.4)};
  max-width: 90vw;
  padding: 3rem 0 5rem;
  text-align: center;
  width: 25rem;

  ${mq.tablet(css`
    padding: 5rem 0 7rem;
  `)}
`;

const EmailLink = styled.a.attrs({
  rel: 'noreferrer noopener',
  target: '_blank',
})`
  color: ${colors.p05};
  display: inline-block;
  font-size: 0.75rem;
  font-weight: ${fonts.weights.bold};
  margin-top: 2.5rem;
  text-decoration: underline;

  &:hover {
    color: ${colors.p06};
    text-decoration: underline;
  }
`;

const Heading = styled.h3`
  color: ${colors.p06};
  font-size: 1.125rem;
  margin-top: 1.5rem;
`;

const InnerContent = styled.div`
  display: inline-block;
  text-align: left;
  width: 11rem;
`;

const LangSelector = styled.div``;

const LangSelectorButton = styled.a`
  color: ${({ isActive }) => (isActive ? colors.p06 : colors.n04)};
  cursor: ${({ isActive }) => (isActive ? 'default !important' : 'pointer')};
  font-size: 0.625rem;
  font-weight: ${fonts.weights.semibold};

  & + & {
    margin-left: 0.25rem;
  }
`;

const LogoWrapper = styled.div`
  margin-top: 1.5rem;
  width: 8.75rem;

  img {
    height: auto;
    width: 100%;
    transform: translateX(-2.5rem);
  }
`;

const SponsorLogoWrapper = styled.div`
  padding-bottom: 1rem;
  margin-top: auto;
  padding-top: 1rem;
  width: 5rem;

  img {
    height: auto;
    opacity: 0.5;
    width: 100%;
  }

  ${mq.tablet(css`
    padding-top: 2rem;
    padding-bottom: 3.75rem;
  `)}
`;

const SubheadingWrapper = styled.div`
  margin-top: 2.5rem;
  position: relative;

  svg {
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    left: -1.5rem;
  }

  h2 {
    font-size: 0.625rem;
    font-weight: ${fonts.weights.bold};
    letter-spacing: 0.1rem;
    line-height: 1.65rem;
    opacity: 0.4;
    text-transform: uppercase;
  }
`;

export {
  AboutContent,
  Background,
  BrandName,
  Content,
  EmailLink,
  Heading,
  InnerContent,
  LangSelector,
  LangSelectorButton,
  LogoWrapper,
  SponsorLogoWrapper,
  SubheadingWrapper,
};
