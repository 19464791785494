export const i18n = {
  en: {
    underConstruction: {
      heading: 'We’re working hard to make this website available soon...',
      intro: 'Association for the Advancement of Tissue Engineering and Cell Based Technologies & Therapies',
      subheading: 'Under development',
    },
  },
  pt: {
    underConstruction: {
      heading: 'Estamos a trabalhar arduamente para terminar o site...',
      intro: 'Association for the Advancement of Tissue Engineering and Cell Based Technologies & Therapies',
      subheading: 'Em desenvolvimento',
    },
  },
};
