import {
  AboutContent,
  Background,
  BrandName,
  Content,
  EmailLink,
  Heading,
  InnerContent,
  LangSelector,
  LangSelectorButton,
  LogoWrapper,
  SubheadingWrapper,
} from './UnderConstruction.style';
import { Icon } from '../../theme/components';
import { graphql, useStaticQuery } from 'gatsby';
import { i18n } from '../../../i18n';
import React, { useState } from 'react';
import logo from '../../images/a4tec-logo.svg';

export const UnderConstruction = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            email
          }
        }
      }
    `
  );

  const [lang, setLang] = useState('en');

  const { email } = site.siteMetadata;
  const { heading, intro, subheading } = i18n[lang].underConstruction;

  return (
    <Background>
      <Content>
        <InnerContent>
          <LangSelector activeLang={lang}>
            <LangSelectorButton isActive={lang === 'en'} onClick={() => setLang('en')}>
              EN
            </LangSelectorButton>
            <LangSelectorButton isActive={lang === 'pt'} onClick={() => setLang('pt')}>
              PT
            </LangSelectorButton>
          </LangSelector>
          <LogoWrapper>
            <img src={logo} />
          </LogoWrapper>
          <BrandName>{intro}</BrandName>
          <SubheadingWrapper>
            <Icon icon="arrowRight" />
            <h2>{subheading}</h2>
          </SubheadingWrapper>
          <Heading>{heading}</Heading>
          <EmailLink href={`mailto:${email}`}>{email}</EmailLink>
        </InnerContent>
      </Content>
      <AboutContent isActive={lang === 'en'}>
        <h2>About</h2>
        <p>
          Association for the Advancement of Tissue Engineering and Cell Based Technologies &amp; Therapies (A4Tec),
          created in 2007, is a non-profit scientific association whose mission is to promote the scientific and
          technological advancement of Human Tissue Engineering and Regenerative Medicine, the education of technical
          and scientific staff in the fields of Biomedicine and the dissemination of scientific research to the
          scientific community and civil society. A4TEC was created and is constituted by researchers and graduated
          students who develop scientific, clinical or technical activities in the area of ​​Tissue Engineering and
          Regenerative Medicine, mainly at 3B’s Research Group (University of Minho). All members of A4TEC must be
          proposed and accepted as members of the Association in accordance with their respective statutes.
        </p>
        <p>As part of its mission, A4TEC has the following priority areas of intervention:</p>
        <ul>
          <li>
            To promote scientific research in the area of ​​Tissue Engineering, Stem Cells and Regenerative Medicine,
            including the resulting Therapies and Technologies, nationally and internationally;
          </li>
          <li>
            To carry out scientific and technological research actions, of an experimental and theoretical nature, in
            the fields of Tissue Science and Engineering;
          </li>
          <li>To promote and coordinate national and international research projects;</li>
          <li>To provide technical and scientific services to the community;</li>
          <li>
            To promote the protection and support of Industrial Property management generated in its projects and
            eventually also in other projects / activities in which it participates;
          </li>
          <li>To stimulate scientific exchange with other national and foreign structures linked to research;</li>
          <li>
            To develop interdisciplinary research by carrying out collaborative projects with other research units;
          </li>
          <li>
            To provide and encourage training at the highest level for young people who want to start a scientific
            career;
          </li>
          <li>
            To promote the organization of international conferences, practical courses and workshops aimed at
            disseminating, exchanging experiences and also advanced training in the areas of Tissue Engineering and
            Regenerative Medicine.
          </li>
        </ul>
        <p>
          A4TEC&#39;s target areas of research are Tissue Engineering, Regenerative Medicine and Nanomedicine, supported
          with basic and applied research in the area of ​​materials science and technology, stem cells and biology /
          biotechnology, namely:
        </p>
        <ul>
          <li>
            Development, characterization and processing of a wide range of polymer-based materials, especially
            macromolecules of natural origin, suitable for different biomedical applications;
          </li>
          <li>
            Development of in vivo protocols to characterize the biological and immunological behaviour of new
            biomaterials, as well as new methodologies for cell cultures (including co-culture models or design of new
            bio-reactors based) and;
          </li>
          <li>
            Development and optimization of methods for the collection, isolation and proliferation / differentiation of
            stem cells from different sources (bone marrow, adipose tissue, amniotic fluid / membrane, umbilical cord,
            and embryonic).
          </li>
        </ul>
        <p>
          A4TEC also develops a wide range of Industrial Property (IP) protection activities in the areas of Life
          Sciences and Materials. In addition to protection, A4TEC coordinates the efforts towards the commercialization
          of various medical technologies, both by supporting entrepreneurial initiatives promoted by fellow
          researchers, and by licensing IP to start-ups, SMEs and large companies. With regard to interaction with the
          business community, A4TEC promotes R&D and consultancy services that respond to the needs of a wide range of
          national and international customers.
        </p>
        <h4>Physical and Human Resources</h4>
        <p>
          A4TEC has participated in several relevant projects over the years, and has been consolidating its park of its
          own scientific equipment, namely for experimentation and laboratorial analysis. Among its main equipment, the
          following stand out:
        </p>
        <ul>
          <li>X-ray photoelectron spectroscopy</li>
          <li>In-vivo x-ray computed microtomography</li>
          <li>Mass spectrometry (MALDI-TOF)</li>
          <li>Scanning electron microscope (SEM)</li>
          <li>Atomic Force Microscopy</li>
          <li>Confocal microscopy</li>
          <li>DOPPLER infusion system</li>
          <li>Thin-film X-ray diffraction (TF-XRD)</li>
          <li>Inverted microscope</li>
          <li>Bioplotter</li>
          <li>Gel permeation chromatography (GPC)</li>
          <li>Isothermal titration calorimeter</li>
          <li>Cryogenic system</li>
          <li>Tensometer</li>
          <li>Surface plasmon resonance (SPR)</li>
          <li>Liquid Scintillation Analyzer</li>
          <li>Rotary atomic microtome</li>
          <li>Hypoxia Integrated Culture System</li>
          <li>Lyophilization</li>
          <li>Thermogravimetric Analysis</li>
        </ul>
        <p>
          Currently, A4TEC is a partner of the project to create a new infrastructure of scientific excellence in the
          area of ​​regenerative and precision medicine - TERM RES-Hub. This very relevant investment will allow A4TEC
          to strengthen its staff with human resources highly qualified (researchers and technicians), as well as
          acquiring new scientific equipment of high interest to the scientific and business community, namely the
          ecosystem in which it operates, focused on the development of research in Tissue Engineering and Regenerative
          Medicine (TERM), Nanomedicine, Biomedical Engineering and Biomaterial Sciences. This scientific infrastructure
          is currently the largest in the National Roadmap (PT) for strategic scientific infrastructures. A4TEC is also
          involved with 3B´s Group at UMinho, the European Institute of Excellence for Tissue Engineering and
          Regenerative Medicine (EXPERTISSUES) and the Guimarães City Council in the launch of the “Cidade de Guimarães
          Institute of Advanced Biomedical Materials” which will be based at AvePark, within the scope of TERM Res-Hub,
          from February 2021.
        </p>
      </AboutContent>
      <AboutContent isActive={lang === 'pt'}>
        <h2>Sobre a A4TEC</h2>
        <p>
          A “Association for the Advancement of Tissue Engineering and Cell Based Technologies & Therapies” – A4TEC,
          fundada em 2007, é uma associação científica sem fins lucrativos que tem por missão a promoção do avanço
          científico e tecnológico da Engenharia de Tecidos Humanos e da Medicina Regenerativa, a formação de quadros
          técnicos e científicos nas áreas da Biomedicina e a divulgação da investigação científica junto da comunidade
          científica e da sociedade civil. A A4TEC foi fundada e integra investigadores e alunos de pós-graduação que
          desenvolvem atividades científicas, clínicas ou técnicas na área da Engenharia de Tecidos e Medicina
          Regenerativa, na sua grande maioria, no Grupo de Investigação 3B’s (Universidade do Minho). Todos os membros
          da A4TEC têm que ser propostos e aceites como sócios da Associação de acordo com os respectivos estatutos.
        </p>
        <p>No âmbito da sua missão, a A4TEC tem como áreas prioritárias de intervenção:</p>
        <ul>
          <li>
            Incrementar a investigação científica na área da Engenharia de Tecidos, Células Estaminais e Medicina
            Regenerativa, incluindo as resultantes Terapias e Tecnologias, a nível nacional e internacional;
          </li>
          <li>
            Realizar ações de investigação científica e tecnológica, de carácter experimental e teórico, nos domínios da
            Ciência e Engenharia de Tecidos;
          </li>
          <li>Promover e coordenar projetos de investigação nacionais e internacionais;</li>
          <li>Prestar serviços técnico-científicos à comunidade;</li>
          <li>
            Promover a proteção e apoiar gestão da Propriedade Industrial gerada nos seus projetos e eventualmente
            também em outros projetos/atividades em que participe;
          </li>
          <li>
            Estimular o intercâmbio científico com outras estruturas nacionais e estrangeiras ligadas à investigação;
          </li>
          <li>
            Desenvolver a interdisciplinaridade através da realização de projetos de colaboração com outras unidades de
            investigação;
          </li>
          <li>
            Proporcionar e incentivar a formação ao nível mais elevado a jovens que pretendam iniciar uma carreira
            científica;
          </li>
          <li>
            Promover a organização de conferências internacionais, cursos práticos e workshops que visem a divulgação, o
            intercâmbio de experiências e ainda a formação avançada nas áreas da Engenharia de Tecidos e Medicina
            Regenerativa.
          </li>
        </ul>
        <p>
          As áreas-alvo de investigação da A4TEC são a Engenharia de Tecidos, Medicina Regenerativa e a Nanomedicina,
          apoiadas com investigação básica e aplicada na área da ciência e tecnologia dos materiais, células estaminais
          e biologia/biotecnologia, nomeadamente:
        </p>
        <ul>
          <li>
            Desenvolvimento, caracterização e processamento de uma ampla gama de materiais de base polimérica,
            especialmente macromoléculas de origem natural, adequados para diferentes aplicações biomédicas;
          </li>
          <li>
            Desenvolvimento de protocolos in vivo para caracterizar o comportamento biológico e imunológico de novos
            biomateriais, assim como novas metodologias de culturas celulares (incluindo modelos de co-cultura, projeto
            de novos bio-reatores baseados por exemplo em perfusão e pressões hidrostáticas) e;{' '}
          </li>
          <li>
            Desenvolvimento e otimização de métodos de recolha, isolamento e proliferação/diferenciação de células
            estaminais de diferentes origens (medula óssea, tecido adiposo, membrana/fluido amniótico, cordão umbilical,
            e embrionárias).
          </li>
        </ul>
        <p>
          A A4TEC desenvolve também um conjunto alargado de atividades na proteção da Propriedade Intelectual (PI) nas
          áreas das Ciências da Vida e dos Materiais. Além da proteção, a A4TEC coordena os esforços de valorização e
          comercialização de várias tecnologias médicas, tanto através do apoio às iniciativas empreendedoras promovidas
          por investigadores seus associados, como pelo licenciamento de PI a start-ups, PMEs e grandes empresas. No que
          diz respeito à interação com o tecido empresarial, a A4TEC desenvolve atividades de prestação de serviços de
          I&D e consultoria que respondem às necessidades de um alargado conjunto de clientes nacionais e
          internacionais.
        </p>
        <h4>Recursos Humanos e Físicos</h4>
        <p>
          A A4TEC é uma associação privada sem fins lucrativos fundada por investigadores a exercer atividade nas suas
          áreas de ação, a maioria no Grupo de Investigação 3B’s, que se encontra sediada nas instalações do Grupo 3B’s
          da Universidade do Minho, com quem tem um protocolo de cooperação para a partilha em ambos os sentidos de
          recursos, nomeadamente de espaços, recursos humanos e equipamentos científicos. Tem neste momento um processo
          em fase final de análise para ser reconhecido como Instituição de utilidade pública pelo gabinete do Primeiro
          Ministro Português, que conta com o apoio da Reitoria da Universidade do Minho.
        </p>
        <p>
          A A4TEC tem ao longo dos anos participado em vários projetos relevantes, e tem vindo a reforçar o seu parque
          de equipamentos científicos próprios, nomeadamente de experimentação e análise laboratorial. De entre os seus
          principais equipamentos destacam-se os seguintes:
        </p>
        <ul>
          <li>Sistema de micro-tomografia computorizada de raios-x in-vivo (Vivo-CT)</li>
          <li>Sistema de Eletroscopia de Fotoelectrões de raios X (XPS)</li>
          <li>Sistema de espectrometria de massa (MALDI-TOF)</li>
          <li>Sistema de microscopia eletrónica de varrimento (SEM)</li>
          <li>Sistema de microscopia de força atómica – AFM</li>
          <li>Sistema de microscopia confocal</li>
          <li>Sistema de perfusão DOPPLER</li>
          <li>Sistema de difração de raios-X (XRD) TF-XRD</li>
          <li>Microscópio invertido</li>
          <li>Sistema de prototipagem rápida (Bioplotter)</li>
          <li>Cromatógrafo de permeação de gel - GPC</li>
          <li>Calorímetro de titulação isotérmica</li>
          <li>Sistema criogénico </li>
          <li>Tensiómetro</li>
          <li>Ressonância Plasmonica Superficial (SPR)</li>
          <li>
            Contador de Cintilografia para microplacas com 2 detetores (Liquid Scintillation Analyzer) Micrótomo
            atomático rotativo
          </li>
          <li>Sistema integrado de Cultura em Hipóxia</li>
          <li>Equipamentos de Liofilização</li>
          <li>Equipamento de análise Termogravimétrica</li>
        </ul>
        <p>
          Atualmente, a A4TEC é parceira no âmbito do projeto de instalação de uma infraestrutura de excelência
          científica na área de medicina regenerativa e de precisão – o TERM RES-Hub, com um investimento muito
          relevante que lhe permitirá reforçar o seu quadro de pessoal com recursos humanos altamente qualificados
          (investigadores e técnicos), bem como adquirir novos equipamentos científico de elevado interesse para a
          comunidade científica e empresarial, nomeadamente do ecossistema onde se insere, focadas no desenvolvimento de
          investigação em Engenharia de Tecidos e Medicina Regenerativa (TERM), Nanomedicina, Engenharia Biomédica e
          Ciência e Tecnologia de Biomateriais. Esta infraestrutura cientifica é atualmente a maior do Roteiro Nacional
          (PT) Estratégico de infraestruturas cientificas. A A4TEC está também envolvida com o Grupo 3B´s da UMinho, o
          Instituto Europeu de Excelência em Engenharia de Tecidos e medicina Regenerativa (EXPERTISSUES) e a Câmara
          Municipal de Guimarães no lançamento do Instituto Cidade de Guimarães de Materiais Biomédicos Avançados que
          terá sede no AvePark, no âmbito da TERM Res-Hub, a partir de Fevereiro de 2021.
        </p>
      </AboutContent>
    </Background>
  );
};
